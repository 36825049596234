import $ from 'jquery';
import '../styles/index.scss'; // webpackでコンパイルするため必須. 実際には.jsには展開されない.



// modal
$(function () {
    $(".js-modal-open").each(function () {
      $(this).on("click", function () {
        var b = $(this).data("target");
        var a = document.getElementById(b);
        $(a).fadeIn();
        return false
      })
    });
    $(".js-modal-close").on("click", function () {
      $(".js-modal").fadeOut();
      return false
    })
  });

// backTop
$(document).ready(function () {
  $("#back-top").hide();
  $(function () {
      $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
              $('#back-top').fadeIn();
          } else {
              $('#back-top').fadeOut();
          }
      });

      $('#back-top a').click(function () {
          $('body,html').animate({
              scrollTop: 0
          }, 800);
          return false;
      });
  });
});

// SP ハンバーガーメニュー
(function($) {
    $(function () {
      $('#nav-toggle').on('click', function() {
        $('body').toggleClass('open');
      });
    });
    $(function () {
      $('.l-head_menu li a').on('click', function() {
        $('body').removeClass('open');
      });
    });
})(jQuery);

// スクロールに合わせて背景画像を横方向に動かす
$(window).on('scroll', function(){

  var scrollTop = $(window).scrollTop();
  var bgPosition = scrollTop / 2; //スクロール後のポジションを指定（値を大きくすると移動距離が小さくなる）

  if(bgPosition){
    $('.company-illus').css('background-position-x', bgPosition + 'px');
  }

});

// スクロール時にふわっと表示させる
$(function(){
  load_effect();
  $(window).scroll(function (){
      scroll_effect();
  });
});

//ふわっとロード
function load_effect(){
  var tt = $(window).scrollTop();
  var hh = $(window).height();
  $('.load-fade').each(function(){
      var yy = $(this).offset().top;
      if (tt > yy - hh){
          $(this).addClass('done');
      }
  });
}

// ふわっとスクロール
function scroll_effect(){
  var tt = $(window).scrollTop();
  var hh = $(window).height();
  $('.scroll-up').each(function(){
      var yy = $(this).offset().top+100;  //効果発生開始タイミングを操作したい場合は数値を変更する
      if (tt > yy - hh){
          $(this).addClass('done');
      }
  });
}

  let slider1 = new Swiper ('.slider1', {
    // slider1 設定
    loop: true,
      autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      reverseDirection: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
  });

  let slider2 = new Swiper ('.slider2', {
    // slider2 設定
    loop: true,
      autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      reverseDirection: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        // clickable: true
      }
  });

  let slider3 = new Swiper ('.slider3', {
    // slider3 設定
    loop: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      reverseDirection: false
      },
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      centeredSlides: true,
      paginationClickable: true,
      slidesPerView: 3,
      spaceBetween: 70,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        // clickable: true
      },
      breakpoints: {
      767: {
        slidesPerView: 2,
        spaceBetween: 10
      }
    }
    });

    let slider4 = new Swiper ('.slider4', {
    // slider4 設定
    loop: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      reverseDirection: false
      },
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      centeredSlides: true,
      paginationClickable: true,
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
      767: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    }
    });

// 一定量スクロールしたら表示
$(window).on('load scroll', function(){
  if ($(window).scrollTop() > 500) {
    $('.fadeIn').fadeIn(400);
   } else {
    $('.fadeIn').fadeOut(400);
   }
});